<template>
  <div
    :class="'recharge-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit">
        <span class="title">選擇線上課程</span>
      <!--  <span class="price">{{ agentInfo.Balance }}</span> -->
      </div>
    </div>

    <div class="credit-block">	
      <van-form @submit="onSubmit">
          <van-field
            v-model="curPerfix"
            is-link
            readonly
            left-icon="aog iconfont icon-ditu"
            @click="showPrefix = true"
          />
         <van-popup v-model:show="showPrefix" round position="bottom">
           <van-picker
             :columns="mobileperfix"
             v-model="selectedlang"
             @cancel="showPrefix = false"
             @confirm="onConfirm"
             :title="PAGELANG.请选择地区"
         	:columns-field-names="{ text: 'courseTitle' ,value:'id' }"
         	:rules="rules.username"
         	defaultIndex=2
           />
         </van-popup>
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>


</template>

<script>
import { showToast, closeToast } from "vant";
import { getCookie } from "../util/index.js";


export default {

  data() {
    return {
      agentInfo: {},
      TradeNo: "",
      showTradeProofMsg: false,
      useraccount: [],
      chargelimit: 0,
      recharge: {},
      PAGELANG: {},
      rules: {},
      labelWidth: "120px",
	  transferUserName:"",
	  goodsTutorId:"",// 导师任务id
	  update:false,//是否被駁回
	  showPrefix:false,
	  selectedlang: [""],
	  curPerfix:"课程A",
	  courseId:1,
	  mobileperfix:[{id:1,courseTitle:"课程A"},{id:2,courseTitle:"课程B"},{id:3,courseTitle:"课程C"}]
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios.get(this.actions.memberInfo).then((response) => {
		  this.transferUserName=response.data.data.realName
	})
	
	this.axios.get(this.actions.teacherSchedule).then((response) => {
		  this.goodsTutorId=response.data.data.goodsTutorId
		  if(response.data.data.curriculum && response.data.data.curriculum.state==4 ){
			  this.update=true
			  this.id=response.data.data.curriculum.id
		  }
	})


	

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    if (this.LANGCODE != "zh") {
      this.labelWidth = "190px";
    }

    this.rules = {
      TradeNo: [{ required: true, message: this.PAGELANG.rulestradeno }],
    };

  
  },
  methods: {
	  
    onConfirm({ selectedOptions }) {
      console.log('selectedOptions',selectedOptions);
      this.showPrefix = false;
      this.curPerfix = selectedOptions[0].courseTitle;
      this.courseId=selectedOptions[0].id;
    },
   
	
    onSubmit() {
   
      this.issubmit = true;
      this.showTradeProofMsg = false;

      let postdata = new Object();
      postdata.goodsTutorId=this.goodsTutorId
      postdata.type=2
      postdata.state=0
      postdata.courseId=this.courseId
      console.log('postdata',postdata);
      if(this.update==true){
      	postdata.id=this.id
      	this.axios.post(this.actions.updateSalonCourseApproval,postdata).then(res=>{
      		  console.log(res)
      		  if(res.data.code==200){
      			  showToast({
      			    message:"提交成功,等待审核",
      			    forbidClick: true,
      				onClose: () => {
      				  this.$router.replace({
      				       path: "/teacherSchedule",
      				      });
      				   },
      			  });
      			  
      		  }else{
      			  showToast({message:res.data.message})
      		  }
      	})
      }else{
      	this.axios.post(this.actions.addSalonCourseApproval,postdata).then(res=>{
      		  console.log(res)
      		  if(res.data.code==200){
      			  closeToast();
      			  showToast({
      			    message:"提交成功,等待审核",
      			    forbidClick: true,
      				onClose: () => {
      				  this.$router.replace({
      				       path: "/teacherSchedule",
      				      });
      				   },
      			  });
      			  
      		  }else{
      			  closeToast();
      			  showToast({message:res.data.message})
      		  }
      	})
      }
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.recharge-page:not(.zh) .trade-proof >>> .van-uploader__upload-text {
  line-height: 14px;
  text-align: center;
}
</style>